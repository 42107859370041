<template>
  <validation-observer ref="observer" v-slot="{ handleSubmit, valid }">
    <b-form @submit.stop.prevent="handleSubmit(createUserAccount)">
      <validation-provider
        :name="$t('firstName')"
        :rules="{ required: true }"
        v-slot="validationContext"
      >
        <b-form-group
          label-class="font-weight-bold"
          :label="$t('firstName')"
          label-for="firstName"
        >
          <b-form-input
            id="firstName"
            v-model="firstName"
            :state="getValidationState(validationContext)"
          ></b-form-input>
          <b-form-invalid-feedback>
            {{ validationContext.errors[0] }}
          </b-form-invalid-feedback>
        </b-form-group>
      </validation-provider>
      <validation-provider
        :name="$t('lastName')"
        :rules="{ required: true }"
        v-slot="validationContext"
      >
        <b-form-group
          class="mt-4"
          label-class="font-weight-bold"
          :label="$t('lastName')"
          label-for="lastName"
        >
          <b-form-input
            id="lastName"
            v-model="lastName"
            :state="getValidationState(validationContext)"
          ></b-form-input>
          <b-form-invalid-feedback>
            {{ validationContext.errors[0] }}
          </b-form-invalid-feedback>
        </b-form-group>
      </validation-provider>
      <validation-provider
        name="email"
        :rules="{ required: true, email }"
        v-slot="validationContext"
      >
        <b-form-group
          class="mt-4"
          label-class="font-weight-bold"
          :label="$t('email')"
          label-for="email"
        >
          <b-form-input
            id="email"
            v-model="email"
            :state="getValidationState(validationContext)"
            @input="email = email.toLowerCase()"
          ></b-form-input>
          <b-form-invalid-feedback>
            {{ validationContext.errors[0] }}
          </b-form-invalid-feedback>
        </b-form-group>
      </validation-provider>
      <validation-provider
        :name="$t('country')"
        :rules="{ required: true }"
        v-slot="validationContext"
      >
        <b-form-group
          class="mt-4"
          label-class="font-weight-bold"
          :label="$t('country')"
          label-for="country"
        >
          <b-form-select
            id="country"
            :state="getValidationState(validationContext)"
            v-model="country"
            :options="countryList"
            value-field="name"
            text-field="name"
          >
            <template v-slot:first>
              <b-form-select-option :value="null" disabled>
                {{ $t("selectAnOption") }}
              </b-form-select-option>
            </template>
          </b-form-select>
          <b-form-invalid-feedback>
            {{ validationContext.errors[0] }}
          </b-form-invalid-feedback>
        </b-form-group>
      </validation-provider>
      <b-form-group
        class="mt-4"
        label-class="font-weight-bold"
        :label="$t('companyName')"
        label-for="companyName"
      >
        <b-form-input id="companyName" v-model="companyName"></b-form-input>
      </b-form-group>
      <validation-provider
        :name="$t('phone')"
        :rules="{ required: true }"
        v-slot="validationContext"
      >
        <b-form-group
          class="mt-4"
          label-class="font-weight-bold"
          :label="$t('phone')"
          label-for="phone"
        >
          <b-form-input
            id="phone"
            v-model="phone"
            :state="getValidationState(validationContext)"
          ></b-form-input>
          <b-form-invalid-feedback>
            {{ validationContext.errors[0] }}
          </b-form-invalid-feedback>
        </b-form-group>
      </validation-provider>
      <b-form-group
        class="mt-4"
        label-class="font-weight-bold"
        :label="$t('jobTitle')"
        label-for="jobTitle"
      >
        <b-form-input id="jobTitle" v-model="jobTitle"></b-form-input>
        <!-- <b-form-select
          id="jobTitle"
          v-model="jobTitle"
          :options="jobList"
          value-field="name"
          text-field="name"
        >
          <template v-slot:first>
            <b-form-select-option :value="null" disabled>
              {{ $t("selectAnOption") }}
            </b-form-select-option>
          </template>
        </b-form-select> -->
      </b-form-group>
      <b-row>
      <b-col class="mt-3">
        <p>{{ $t("button.alreadyHaveAccount") }} <span> <button class="no-decoration-btn link-btn" @click="toggleForm" type="button">{{ $t("button.login") }}</button></span></p>
      </b-col>
    </b-row>
      <b-row class="no-gutters mt-4">
        <b-col class="col-auto">
          <validation-provider
            :rules="{ checkboxRequired: true }"
            v-slot="validationContext"
          >
            <b-form-checkbox
              v-model="agreeToTerms"
              :state="getValidationState(validationContext)"
            >
              {{ $t("agreeToTermsText") }}
              <b-link
                target="_blank"
                :href="getExternalLink(termsLink)"
                class="link text-red"
                >{{ $t("termsCondidionsLink") }}</b-link
              >
              {{ ` ${$t("and")} ` }}
              <b-link
                target="_blank"
                :href="getExternalLink(privacyLink)"
                class="link text-red"
                >{{ $t("privacyPolicyLink") }}</b-link
              >
              {{ "." }}
            </b-form-checkbox>
            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </validation-provider>
        </b-col>
      </b-row>
      <b-form-checkbox v-model="newsletter">{{
        $t("newsletterText")
      }}</b-form-checkbox>
      <b-row class="no-gutters justify-content-end mt-5">
        <b-col class="col-12 col-sm-auto">
          <b-button variant="none" class="no-decoration-btn register-btn" :disabled="!valid" block type="submit">
            {{ $t("button.createAccount") }}
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </validation-observer>
</template>

<script>
import Utils from "./../Utils";
export default {
  props:{
    toggleForm:{
      type:Function,
      required:false,
      default:()=>{}
    }
  },
  data() {
    return {
      jobList: [],
      countryList: Utils.COUNTRY_LIST,
      termsLink: {
        hrefEn: "https://emotiva.it/en/empower-term-of-service/",
        hrefIt: "https://emotiva.it/en/empower-term-of-service/"
      },
      privacyLink: {
        hrefEn: "https://emotiva.it/en/empower-privacy-policy",
        hrefIt: "https://emotiva.it/empower-privacy-policy-2/"
      }
    };
  },
  created() {
    this.$store.commit("auth/RESET_REGISTER_USER_MODEL");
  },
  computed: {
    firstName: {
      get() {
        return this.$store.getters["auth/getRegisterFirstName"];
      },
      set(firstName) {
        this.$store.commit("auth/SET_FIRST_NAME", firstName);
      }
    },
    lastName: {
      get() {
        return this.$store.getters["auth/getRegisterLastName"];
      },
      set(lastName) {
        this.$store.commit("auth/SET_LAST_NAME", lastName);
      }
    },
    email: {
      get() {
        return this.$store.getters["auth/getRegisterEmail"];
      },
      set(email) {
        this.$store.commit("auth/SET_EMAIL", email);
      }
    },
    country: {
      get() {
        return this.$store.getters["auth/getRegisterCountry"];
      },
      set(country) {
        this.$store.commit("auth/SET_COUNTRY", country);
      }
    },
    companyName: {
      get() {
        return this.$store.getters["auth/getRegisterCompanyName"];
      },
      set(companyName) {
        this.$store.commit("auth/SET_COMPANY_NAME", companyName);
      }
    },
    phone: {
      get() {
        return this.$store.getters["auth/getRegisterPhone"];
      },
      set(phone) {
        this.$store.commit("auth/SET_PHONE", phone);
      }
    },
    jobTitle: {
      get() {
        return this.$store.getters["auth/getRegisterJobTitle"];
      },
      set(jobTitle) {
        this.$store.commit("auth/SET_JOB_TITLE", jobTitle);
      }
    },
    agreeToTerms: {
      get() {
        return this.$store.getters["auth/isRegisterAgreeToTerms"];
      },
      set(agreeToTerms) {
        this.$store.commit("auth/SET_AGREE_TO_TERMS", agreeToTerms);
      }
    },
    newsletter: {
      get() {
        return this.$store.getters["auth/isRegisterNewsletter"];
      },
      set(newsletter) {
        this.$store.commit("auth/SET_NEWSLETTER", newsletter);
      }
    }
  },
  methods: {
    getExternalLink(linkItem) {
      return Utils.getExternalLinkByLang({
        linkEn: linkItem.hrefEn,
        linkIt: linkItem.hrefIt
      });
    },
    createUserAccount() {
      this.$store
        .dispatch("auth/createUserAccount")
        .then(() => {
          this.$store.commit("success/SET_SUCCESS_INFO", {
            titleText: "dialog.success.registerTitle",
            messageText: "dialog.success.registerMessage"
          });
        })
        .catch(() => {});
    },
    getValidationState({ dirty, validated, valid = null, untouched }) {
      if (!untouched && (dirty || validated)) return valid ? null : valid;
      else return null;
    }
  }
};
</script>

<style scoped>
.register-btn{
  background-color: var(--emotiva-pink);
  color:#fff;
  padding: 15px;
  border-radius: 28px;
  min-width: 109px;
  min-height: 56px;
}
.link-btn {
  font-weight: bold;
 
}

.link-btn:hover {
  text-decoration: underline;
}
</style>
